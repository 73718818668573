import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import NavbarService from "../Navbar/NavbarService";
import "./Contact.css";
import BassURl from "../Api/Api";
import {
  FaPhoneAlt,
  FaRegClock,
  FaPhoneSquare,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaMobileAlt,
} from "react-icons/fa";
import { Ri24HoursFill } from "react-icons/ri";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BassURl}api/contact-enquiry`,
        formData
      );

      // Handle successful form submission here
      console.log("Form submitted successfully");
      window.location.href = "/thank-you";
      // Optionally, reset the form fields
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error here
    }
  };

  return (
    <>
      <NavbarService />
      <img
        loading="lazy"
        src="/assets/img/about-hero.jpg"
        alt=""
        width="100%"
        style={{ height: "90vh", marginBottom: "25px" }}
      />
      {/* <div
            className="image-cover hero_banner "
            style={{ background: "url(/assets/img/uni-banner.jpg) no-repeat" }}
            data-overlay={4}
          </div> */}
      <div className="contact ">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className="contact-page-form">
                <h4>Feel Free To Make A Call Request Now</h4>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    {/* <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </div> */}
                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-lg-12">
                      <select
                        className="form-control"
                        name="services"
                        value={formData.services}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select a Service</option>
                        <option value="ARO Install">ARO Install</option>
                        <option value="ARO Repair">ARO Repair</option>
                      </select>
                    </div>

                    <div className="col-lg-12">
                      <textarea
                        rows={6}
                        className="form-control"
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                      <button type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row pt-3">
                <div className="col-12">
                  <div className="border p-3">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      {/* <Ri24HoursFill size={30} /> */}
                      <div className="d-flex align-items-center flex-column">
                        <h5>Opening Hours</h5>
                        <div className="d-flex align-items-center">
                          <p>9:00 AM - 8:00 PM</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pt-3">
                  <div className="border p-3  d-flex justify-content-center align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <FaPhoneSquare size={20} color="#1E3A8A" />
                      </div>
                      <div>
                        <p className="mb-0">0522-4060673</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-3">
                  <div className="border p-3 d-flex justify-content-center align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <FaEnvelope size={20} color="#1E3A8A" />
                      </div>
                      <div>
                        <p className="mb-0">Care@aquamart.co.in</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12  pt-3">
                  <div className="border p-3  d-flex justify-content-center align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <FaPhone size={20} color="#1E3A8A" />
                      </div>
                      <div>
                        <p className="mb-0">7388188860, 9936844403</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12  ">
                  <div className="border p-3 d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <FaMapMarkerAlt size={20} color="#1E3A8A" />
                      </div>
                      <div>
                        <p className="mb-0">
                          {" "}
                          Near Pantaloon outlet, engineering college chouraha,
                          Aliganj, lucknow
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="text-center py-2">
              <h3 className="mb-3 font-weight-bold">
                We're Here For All Your Water Purifier Needs
              </h3>
              <p className="text-muted">
                At Aquamart, we are committed to providing top-notch water
                purifier repair services for all major brands in Lucknow.
                Whether it's a routine maintenance check or an urgent repair,
                our team of expert technicians is ready to ensure your water
                purifier is functioning at its best. Your water quality and
                health are our priority, and we strive to deliver reliable
                service to keep your system running smoothly.
              </p>
            </div>
            {/* Installation */}
            <div className="col-12 col-md-4">
              <div
                className="card"
                style={{
                  backgroundColor: "#107cc44d",
                  height: "auto",
                  width: "100%",
                }}
              >
                <div className="p-3 shadow rounded service-box">
                  <div className="centered-image">
                    <img
                      src="https://t3.ftcdn.net/jpg/05/50/75/32/360_F_550753232_wdNLiwVjyZN2czce8Mg9b9WuJ7zbfS6O.jpg"
                      alt="Centered Icon"
                      width="60"
                      height="60"
                    />
                  </div>
                  <h4
                    className="mb-3 font-weight-bold"
                    style={{ color: "rgb(19, 62, 106)" }}
                  >
                    Installation
                  </h4>
                  <p>
                    Our professional technicians ensure precise installation of
                    your water purifier, optimizing its performance from the
                    start. This process guarantees long-lasting efficiency and
                    worry-free operation for your system.
                  </p>
                </div>
              </div>
            </div>

            {/* Maintenance */}
            <div className="col-12 col-md-4">
              <div
                className="card"
                style={{
                  backgroundColor: "#107cc44d",
                  height: "auto",
                  width: "100%",
                }}
              >
                <div className="p-3 shadow rounded service-box">
                  <div className="centered-image">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/6661/6661913.png"
                      alt="Centered Icon"
                      width="60"
                      height="60"
                    />
                  </div>
                  <h4
                    className="mb-3 font-weight-bold"
                    style={{ color: "rgb(19, 62, 106)" }}
                  >
                    Maintenance
                  </h4>
                  <p>
                    Regular maintenance ensures your water purifier stays in
                    optimal condition. Our team offers flexible plans for
                    scheduled checkups, thorough cleaning, and necessary
                    replacements, ensuring uninterrupted clean water flow.
                  </p>
                </div>
              </div>
            </div>

            {/* Repairs */}
            <div className="col-12 col-md-4">
              <div
                className="card"
                style={{
                  backgroundColor: "#107cc44d",
                  height: "auto",
                  width: "100%",
                }}
              >
                <div className="p-3 shadow rounded service-box">
                  <div className="centered-image">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/9759/9759793.png"
                      alt="Centered Icon"
                      width="60"
                      height="60"
                    />
                  </div>
                  <h4
                    className="mb-3 font-weight-bold"
                    style={{ color: "rgb(19, 62, 106)" }}
                  >
                    Repairs
                  </h4>
                  <p>
                    In case of any issues, our technicians offer fast and
                    reliable repairs using genuine parts. We diagnose the
                    problem quickly and restore your system’s functionality,
                    ensuring minimal downtime and optimal performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
