

import React from "react";
import BassURl from "../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, addToCartAction } from "../Redux/Action/CartAction";
import { colors } from "@material-ui/core";
import parse from "html-react-parser";
import { PopupForm } from "../Model/PopupForm";
import ProductAddButton from "../Component/ProductAddButton";

function NewTopProduct({ itemsPro, onAddToCartClicked }) {
  console.log(itemsPro);
  const dispatch = useDispatch();
  function addtocart() {
    dispatch(addToCartAction(itemsPro, 1));
  }
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  return (
    <>
      <div className="property-listing property-2 productHeight items">
        <div className="listing-img-wrapper">
          <div className="list-img-slide">
            <div className="click">
              <div>
                <a href={`/product/${itemsPro?.product_url}`}>
                  <img
                    src={BassURl + itemsPro?.product_image}
                    // src="assets/img/new-4.png"
                    className="img-fluid imgProduct "
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="listing-detail-wrapper">
          <div className="listing-short-detail-wrap">
            <div className="_card_list_flex">
              <div className="_card_flex_01">
                <h4 className="listing-name verified">
                  <a
                    href={`/product/${itemsPro?.product_url}`}
                    className="prt-link-detail"
                  >
                    {itemsPro?.product_title}
                  </a>
                </h4>
              </div>
              <br />
            </div>
            <div
              className="footer-first row "
              style={{
                marginTop: "12px",
              }}
            >
              <div className="foot-rates p-0 col-4 d-flex justify-content-center align-items-center">
                <span
                  className="elio_rate good d-flex  align-items-center justify-content-between "
                  style={{
                    color: "#2f2f2f",
                  }}
                >
                  4.2{" "}
                  <i
                    className="fa fa-star "
                    style={{
                      color: "#feae63",
                      marginLeft: "5px",
                    }}
                  />
                </span>
              </div>
              <div className=" p-0 col-4 d-flex justify-content-end align-items-center">
                <h6 className=" mb-0  del-text">
                  ₹ &nbsp;
                  {itemsPro?.product_price}
                </h6>
              </div>
              <div className="p-0 col-4 d-flex justify-content-end align-items-center">
                <del className="del-text2">
                  ₹ &nbsp;
                  {itemsPro?.product_mrp}
                </del>
              </div>
            </div>
            <div className="row"></div>
            {/* <div className="footer-flex d-flex justify-content-center w-100">
             
              <ProductAddButton data={itemsPro} />
            </div> */}
          </div>
        </div>
        {/* <div className="off">
                <img width={"20px"} src="assets/img/off.png" alt="" />
              </div> */}
        {/* <div className="listing-detail-footer"></div> */}
      </div>
    </>
  );
}

export default NewTopProduct;
